<template>
  <b-overlay
    rounded
    opacity="1"
    :show="loading"
    :spinner-variant="variant"
  >
    <router-link
      :to="link"
    >
      <b-card
        :footer="label"
        :footer-bg-variant="`pale-${variant}`"
        :footer-class="`px-2 py-1 font-weight-bolder border-bottom border-right border-left rounded-bottom border-pale-${variant}`"
        :body-class="`px-2 py-3 border-top border-right border-left rounded-top border-pale-${variant}`"
      >
        <b-card-text
          :class="`h1 text-right text-${variant}`"
        >
          {{ count }}
        </b-card-text>
      </b-card>
    </router-link>
  </b-overlay>
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
  name: 'PaleCard',
  middleware: ['auth'],
  props: {
    count: {
      type: [String, Number],
      required: true,
      default: () => {
        return 0
      }
    },
    label: {
      type: String,
      required: true,
      default: () => {
        return ''
      }
    },
    variant: {
      type: String,
      required: true,
      default: () => {
        return 'primary'
      }
    },
    link: {
      required: false,
      type: Object,
      default: () => {
        return {
          name: '',
          params: {
          }
        }
      }
    },
    loading: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data () {
    return {

    }
  },

  mounted () {
    core.index()
  }

}
</script>
<style>
.card-footer {
  border-top: 1px solid white !important;
}

.rounded-bottom {
  border-radius: 0px 0px 9px 9px !important;
}

.rounded-top {
  border-radius: 9px 9px 0px 0px !important;
}

.border-pale-primary {
  border-color: var(--iq-light-primary) !important;
}

.border-pale-secondary {
  border-color: var(--iq-light-secondary) !important;
}

.border-pale-warning {
  border-color: var(--iq-light-warning) !important;
}

.border-pale-danger {
  border-color: var(--iq-light-danger) !important;
}

.border-pale-success {
  border-color: var(--iq-light-success) !important;
}

.border-pale-info {
  border-color: var(--iq-light-info) !important;
}
</style>
