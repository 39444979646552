<template>
  <b-container
    fluid
  >
    <b-row class="mb-4">
      <b-col
        cols="12"
      >
        <b-card
          title="Payment Requests"
          sub-title="My Requests"
          class="shadow-sm"
        >
          <b-row>
            <b-col
              cols="6"
              md="2"
              lg="2"
              class="p-2"
              order="1"
            >
              <PaleCard
                label="Pending"
                variant="warning"
                :count="expenses.pending"
                :link="{ name: 'user.expenses.payment-requests', params: { user: 'pending' }}"
                :loading="fetching"
              />
            </b-col>
            <b-col
              cols="6"
              md="2"
              lg="2"
              class="p-2"
              order="2"
            >
              <PaleCard
                label="Canceled"
                variant="danger"
                :count="expenses.canceled"
                :link="{ name: 'user.expenses.payment-requests', params: { user: 'histories', status: 'Canceled' }}"
                :loading="fetching"
              />
            </b-col>
            <b-col
              cols="6"
              md="2"
              lg="2"
              order="6"
              order-md="3"
            />
            <b-col
              cols="6"
              md="2"
              lg="2"
              class="p-2"
              order="3"
              order-md="4"
            >
              <PaleCard
                label="Processing"
                variant="warning"
                :count="expenses.processing"
                :link="{ name: 'user.expenses.payment-requests', params: { user: 'pending' }}"
                :loading="fetching"
              />
            </b-col>
            <b-col
              cols="6"
              md="2"
              lg="2"
              class="p-2"
              order="4"
              order-md="5"
            >
              <PaleCard
                label="Released"
                variant="success"
                :count="expenses.released"
                :link="{ name: 'user.expenses.payment-requests', params: { user: 'histories', status: 'Released' }}"
                :loading="fetching"
              />
            </b-col>
            <b-col
              cols="6"
              md="2"
              lg="2"
              class="p-2"
              order="5"
              order-md="6"
            >
              <PaleCard
                label="Disapproved"
                variant="danger"
                :count="expenses.disapproved"
                :link="{ name: 'user.expenses.payment-requests', params: { user: 'histories', status: 'Disapproved' }}"
                :loading="fetching"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
      >
        <b-card
          title="Payment Requests"
          sub-title="My Approvals"
          class="shadow-sm"
        >
          <b-row>

            <b-col
              offset-md="6"
              cols="6"
              md="2"
              lg="2"
              class="p-2"
            >
              <PaleCard
                label="Pending"
                variant="warning"
                :count="approvals.pending"
                :link="{ name: 'user.request-approvals', params: { approver: 'pending' }}"
                :loading="fetching"
              />
            </b-col>
            <b-col
              cols="6"
              md="2"
              lg="2"
              class="p-2"
            >
              <PaleCard
                label="Approved"
                variant="success"
                :count="approvals.approved"
                :link="{ name: 'user.request-approvals', params: { approver: 'histories', status: 'Approved' }}"
                :loading="fetching"
              />
            </b-col>
            <b-col
              cols="6"
              md="2"
              lg="2"
              class="p-2"
            >
              <PaleCard
                label="Disapproved"
                variant="danger"
                :count="approvals.disapproved"
                :link="{ name: 'user.request-approvals', params: { approver: 'histories', status: 'Disapproved' }}"
                :loading="fetching"
              />
            </b-col>

          </b-row>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { UserDashboardService } from '@/services'
import PaleCard from '@/components/custom/PaleCard'

export default {

  name: 'UserDashboard',

  middleware: ['auth', 'user'],

  components: {
    PaleCard
  },

  metaInfo: () => ({
    title: 'Dashboard'
  }),

  data () {
    return {
      fetching: false,
      expenses: {
        pending: 0,
        processing: 0,
        disapproved: 0,
        released: 0,
        canceled: 0
      },
      approvals: {
        pending: 0,
        disapproved: 0,
        approved: 0
      }
    }
  },

  mounted () {
    core.index()
    this.getStatistics()
  },

  methods: {

    async getStatistics () {
      this.fetching = true
      return await UserDashboardService.get().then(
        ({ data }) => {
          this.expenses.pending = data.request_pending
          this.expenses.processing = data.request_processing
          this.expenses.disapproved = data.request_disapproved
          this.expenses.released = data.request_released
          this.expenses.canceled = data.request_canceled
          this.approvals.pending = data.approver_pending
          this.approvals.disapproved = data.approver_disapproved
          this.approvals.approved = data.approver_approved
          this.fetching = false
        }
      )
    }

  }
}
</script>
